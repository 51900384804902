import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    const consoleStory = document.querySelector( 'turbo-frame#console_story' );
    const consoleStoryInstance = document.querySelector( '.turbo-console-story' );
    const labResource = consoleStory.getAttribute( 'data-lab-resource' );
    const instanceResource = consoleStory.getAttribute( 'data-instance-resource' );

    const updateConsoleStory = () => {
      consoleStory.src = consoleStory.getAttribute( 'data-src' );
    };

    if( labResource === 'true' && instanceResource === 'true' ) {
      updateConsoleStory();
    } else if( labResource === 'false' ) {
      updateConsoleStory();
    }

    // Add an event listener for Turbo Stream updates
    document.addEventListener( 'turbo:before-stream-render', ( event ) => {
      if( event.target.getAttribute( 'target' ) === consoleStoryInstance.id ) {
        updateConsoleStory();
      }
    } );
  }
}
